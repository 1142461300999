@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 13px;
  right: 13px;
}

.pos-relative {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  margin-top: 70px;
}

.buttonRight {
  display: flex;
  justify-content: space-between;
}

.buttonCenter {
  display: flex;
  justify-content: center;
}

body.ReactModal__Body--open{overflow:hidden}.ReactModal__Overlay.ReactModal__Overlay{position:fixed;top:0;left:0;right:0;bottom:0}.ReactModal__Overlay{z-index:10;background-color:rgba(59,59,59,0.9);display:flex;align-items:center;justify-content:center;-webkit-animation:fadeIn .3s;animation:fadeIn .3s}.ReactModal__Overlay.ReactModal__Content{background-color:#FFFFFF !important;padding:0 !important;border:none !important;position:static !important;outline:none;border-radius:0.375rem}.ReactModal__Overlay .modal-container{position:relative;max-width:95vw;max-height:90vh}.ReactModal__Overlay .modal-container .cob-btn{border-radius:3.125rem}.ReactModal__Overlay .modal-container .close-modal{position:absolute;margin:0;top:-1.25rem;right:-1.25rem;box-shadow:0 0.25rem 0 0 rgba(0,0,0,0.21) !important;z-index:11}.ReactModal__Overlay .modal-container .modal-content{padding:1.25rem 2.5rem;overflow:scroll;max-height:95vh;color:#1C2733;font-size:0.875rem;border:none}.ReactModal__Overlay .modal-container .modal-title .title{font-size:1.5rem}

