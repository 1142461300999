body.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ReactModal__Overlay {
    z-index: 10;
    background-color: rgba(#3B3B3B, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn .3s;

    &.ReactModal__Content {
        background-color: #FFFFFF !important;
        padding: 0 !important;
        border: none !important;
        position: static !important;
        outline: none;
        border-radius: 0.375rem;
    }

    .modal-container {
        position: relative;
        max-width: 95vw;
        max-height: 90vh;

        .cob-btn {
            border-radius: 3.125rem;
        }

        .close-modal {
            position: absolute;
            margin: 0;
            top: -1.25rem;
            right: -1.25rem;
            box-shadow: 0 0.25rem 0 0 rgba(0, 0, 0, 0.21) !important;
            z-index: 11;
        }

        .modal-content {
            padding: 1.25rem 2.5rem;
            overflow: scroll;
            max-height: 95vh;
            color:  #1C2733;
            font-size: 0.875rem;
            border: none;
        }

        .modal-title {
            .title {
                font-size: 1.5rem;
            }
        }

    }
}
